.gearbox-rotation {

  &.gearbox-rotation--green {

    .gearbox-rotation__draggable-layer {

      &::after {
        position: absolute;
        content: "";
        width: 5.2rem;
        height: 1.3rem;
        right: 1.3rem;
        top: 5.8rem;
        background-image: url('../images/svg/arrow-right-green.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;

        @include media-breakpoint-up(sm) {
          width: 6.2rem;
          height: 1.5rem;
          right: 1.5rem;
          top: 6.7rem;
        }
      }
    }
  }
}

.gearbox-rotation__wheel {
  width: 13rem;
  height: 13rem;
  display: block;
  background-color: #dde5ec;
  border-radius: 12.5rem;
  position: relative;
  margin: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-pref */

  @include media-breakpoint-up(sm) {
    width: 15rem;
    height: 15rem;
  }
}

.gearbox-rotation__in {
  width: 12.4rem;
  height: 12.4rem;
  display: block;
  background-color: #dde5ec;
  border-radius: 7.1rem;
  position: absolute;
  left: 0.34rem;
  top: 0.3rem;
  z-index: 1;

  @include media-breakpoint-up(sm) {
    width: 14.2rem;
    height: 14.2rem;
    left: 0.4rem;
    top: 0.4rem;
  }
}

.gearbox-rotation__scale {
  z-index: 2;
  position: absolute;
  opacity: .8;
}

.gearbox-rotation__gear {
  position: absolute;
  width: 7.6rem;
  height: 7.6rem;
  left: 2.7rem;
  top: 2.7rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: 3;

  @include media-breakpoint-up(sm) {
    width: 9.6rem;
    height: 9.6rem;
  }
}

.gearbox-rotation__pointer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
}

.gearbox-rotation__draggable-layer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 13rem;
  height: 13rem;
  display: block;
  background-color: transparent;
  border-radius: 7.5rem;

  @include media-breakpoint-up(sm) {
    width: 15rem;
    height: 15rem;
  }
}

.gearbox-rotation__form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
}

.gearbox-rotation__label {
  margin-right: 0.8rem;
  margin-bottom: 0;
}

.gearbox-rotation__input {
  width: 5rem;
}

.gearbox-rotation__deg {
  margin-left: 0.3rem;
}

.gearbox-rotation__after-text {
  position: absolute;
  top: 0.4rem;
  right: -0.7rem;
}