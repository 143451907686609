.type-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(207,218,227,0.97);
  z-index: 9999;
  padding-top: 5rem;
  pointer-events: none;
  opacity: 0;
  transform: translateY(1rem);
  transition: all 500ms ease;

  &.type-modal--opened {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }

  &.type-modal--4-cols {

    .type-modal__content {

      @include media-breakpoint-up(lg) {
        max-width: 74rem;
      }
    }

    .type-modal__item {
      width: 25%;
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.type-modal__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    width: 4rem;
    height: 4rem;
    top: 3rem;
    right: 3rem;
  }
}

.type-modal__close-icon {
  position: absolute;
  left: 0.7rem;
  top: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
  fill: $blue-color-1;

  @include media-breakpoint-up(md) {
    left: 0.6rem;
    top: 0.6rem;
    width: 2.8rem;
    height: 2.8rem;
  }
}

.type-modal__wrapper {
  overflow: auto;
  height: 100%;
}

.type-modal__content {
  width: 100%;
  padding: 3rem 1.5rem;

  @include media-breakpoint-up(sm) {
    padding: 0;
    max-width: 50rem;
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    max-width: 55.5rem;
    padding: 4rem 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.type-modal__headline {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
}

.type-modal__items {
  display: flex;
  flex-wrap: wrap;

  &.type-modal__items--small {

    .type-modal__item {
      width: 25%;
    }
  }
}

.type-modal__item {
  width: 33.33%;
  padding: 0 0.5rem;
}

.type-modal__input {
  display: none;

  &:checked {

    &+.type-modal__label {
      border-color: $blue-color-1;
    }
  }
}

.type-modal__label {
  position: relative;
  width: 100%;
  padding-top: calc(100% - 0.8rem);
  background-color: #fff;
  margin-bottom: 0.7rem;
  border: 0.4rem solid transparent;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover {
    border-color: $blue-color-3;
  }
}

.type-modal__image {
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}