.gutter-1 {
  margin-right: -1px;
  margin-left: -1px;

  > .col,
  > [class*="col-"] {
    padding-right: 1px;
    padding-left: 1px;
  }
}

.gutter-5 {
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.gutter-8 {
  margin-right: -8px;
  margin-left: -8px;

  > .col,
  > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.gutter-10 {
  margin-right: -10px;
  margin-left: -10px;

  > .col,
  > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.padding-tb-1 {
  padding: 30px 0;
}