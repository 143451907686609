.btn {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  text-transform: uppercase;
  padding: 1.1rem 2.2rem;
  border-radius: 2.3rem;
  color: #fff;
  transition: all 200ms ease;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  &:focus {
    box-shadow: none;
  }

  &:active {
    transform: scale(0.95);
  }

  &.btn--primary {
    background-color: $blue-color-1;

    &:hover {
      background-color: $blue-color-7;
    }
  }

  &.btn--secondary {
    background-color: $orange-color-1;

    &:hover {
      background-color: $orange-color-2;
    }
  }

  &.btn--tertiary {
    background-color: $blue-color-10;

    &:hover {
      background-color: $blue-color-11;
    }
  }

  &.btn--quaternary {
    background-color: $yellow-color-1;

    &:hover {
      background-color: $yellow-color-4;
    }
  }

  &.btn--quinary {
    background-color: $red-color-4;

    &:hover {
      background-color: $red-color-5;
    }
  }

  &.btn--has-icon {
    position: relative;
    padding-left: 4.8rem;
  }

  &.btn--disabled {
    background-color: $blue-color-8 !important;
    pointer-events: none;
  }

  &.btn--small {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  &.btn--yt {
    padding-left: 5.8rem;
    white-space: nowrap;

    .btn__icon {
      width: 2.2rem;
      height: 1.6rem;
      left: 2.2rem;
    }
  }
}

.btn__icon {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  fill: #fff;
}

.btn-simple {
  position: relative;
  padding-left: 2.2rem;
  color: $blue-color-1;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.1rem;

  &.btn-simple--small {
    padding-left: 1.6rem;

    .btn-simple__icon {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  &:hover {
    color: $blue-color-1;
    text-decoration: none;

    .btn-simple__icon {
      fill: $blue-color-1;
    }
  }
}

.btn-simple__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  fill: $blue-color-2;
  transition: all 200ms ease;
}

.btn-simple-toolbar {
  display: flex;
  justify-content: center;

  .btn-simple {

    &+.btn-simple {
      margin-left: 2.3rem;
    }
  }
}

.btn-outline {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: $blue-color-1;
  padding: 0.8rem 1.8rem 0.8rem 1.8rem;
  border: 0.1rem solid $blue-color-2;
  display: inline-block;
  border-radius: 1.6rem;
  text-decoration: none;
  transition: all 200ms ease;

  &:hover {
    color: #fff;
    background-color: $blue-color-2;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.95);
  }
}