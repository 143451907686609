.help {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }
}

.help__icon {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  fill: $blue-color-1;
}

.popover {
  font-size: 1.3rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 3.5rem rgba(0,048,87,0.2);
  min-width: 16rem;
  background-color: transparent;

  .arrow {
    width: 2.6rem;

    &::before {
      display: none;
    }
  }

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  @include media-breakpoint-up(sm) {
    max-width: 38.5rem;
  }
}

.popover-close {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 1.4rem;
  right: 1.4rem;
  background-image: url('../images/svg/cross-gray.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.popover-header {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 1.5rem 4rem 1.5rem 3rem;
  background-color: $blue-color-1;
  color: #fff;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;

  &::before {
    display: none !important;
  }
}

.popover-body {
  padding: 1.5rem 3rem;
  color: $blue-color-1;
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 2rem;

  > .arrow {

    &::after {
      bottom: -0.9rem;
      border-width: 1.5rem 1.3rem 0 1.3rem;
    }
  }
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 2rem;

  > .arrow {

    &::after {
      top: -0.9rem;
      border-width: 0 1.3rem 1.5rem 1.3rem;
      border-bottom-color: $blue-color-1;
    }
  }
}