.form-control {
  height: 2.6rem;
  border: 0.1rem solid $blue-color-4;
  border-radius: 0;
  font-size: 1.3rem;
  color: $blue-color-1;
  transition: all 200ms ease;

  &.form-control--right {
    text-align: right;
  }

  &:focus {
    border-color: $blue-color-5;
    outline: none;
    box-shadow: none;
    color: $blue-color-1;
  }

  &:disabled {
    background-color: $blue-color-6;
    border-color: $blue-color-6;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

select {
  height: 2.6rem;
  width: 100%;
  color: $blue-color-1;
  border: 0.1rem solid $blue-color-4;
  padding: 0 2.2rem 0 0.4rem;
  background-image: url('../images/select-arrow.jpg');
  background-position: calc(100% - 0.2rem) 50%;
  background-repeat: no-repeat;
  background-color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  transition: all 200ms ease;

  &:focus {
    outline: none;
    border-color: $blue-color-5;
  }
}

select::-ms-expand {
  display: none;
}


.form-check-label {
  padding-top: 0.2rem;
  margin-left: 0.9rem;
}

.form-check {
  margin-bottom: 0.4rem;
}

.form-check-inline {
  margin-right: 1rem;
  margin-bottom: 0;

  .form-check-label {
    padding-top: 0;
    margin-left: 0;
  }
}

input[type="radio"] {

  &:checked {

    &+.form-check-label {
      font-weight: 900;
    }
  }
}



.label {
  margin-bottom: 0;

  &.label--bold {
    font-weight: 900;
  }
}

.form-group {
  margin-bottom: 0.2rem;
  position: relative;

  &.form-group--has-warning {

    .form-group__input-wrapper {
      padding-right: 3rem;

      .form-group__warning-sign {
        position: absolute;
        width: 1.8rem;
        height: 1.6rem;
        top: 50%;
        right: 0.3rem;
        transform: translateY(-50%);
        background-image: url('../images/svg/warning-yellow.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
      }

      &.form-group__input-wrapper--custom {
        padding-right: 0;

        .form-group__warning-sign {
          position: relative;
          top: auto;
          right: auto;
          transform: none;
          margin-left: 1rem;
          flex-shrink: 0;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;

        .form-group__warning-sign {
          right: -2.5rem;
        }
      }
    }

    select, .form-control {
      border-color: $yellow-color-1;
      box-shadow: inset 0 0 0 1px $yellow-color-1;
      background-color: $yellow-color-2;
    }
  }

  &.form-group--has-error {

    .form-group__input-wrapper {
      padding-right: 3rem;

      .form-group__warning-sign {
        position: absolute;
        width: 1.8rem;
        height: 1.6rem;
        top: 50%;
        right: 0.3rem;
        transform: translateY(-50%);
        background-image: url('../images/svg/warning-red.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        cursor: pointer;
      }

      &.form-group__input-wrapper--custom {
        padding-right: 0;

        .form-group__warning-sign {
          position: relative;
          top: auto;
          right: auto;
          transform: none;
          margin-left: 1rem;
          flex-shrink: 0;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;

        .form-group__warning-sign {
          right: -2.5rem;
        }
      }
    }

    select, .form-control {
      border-color: $red-color-1;
      box-shadow: inset 0 0 0 1px $red-color-1;
      background-color: $red-color-2;
    }
  }

  &.form-group--has-help {

    .form-group__input-wrapper {
      padding-right: 3rem;

      &.form-group__input-wrapper--custom {
        padding-right: 0;

        .help {
          position: relative;
          top: auto;
          right: auto;
          transform: none;
          margin-left: 1rem;
          flex-shrink: 0;
        }
      }

      @include media-breakpoint-up(md) {
        padding-right: 0;
      }
    }

    .help {
      right: 0.3rem;

      @include media-breakpoint-up(md) {
        right: -2.5rem;
      }
    }

    &.form-group--has-warning {

      .form-group__input-wrapper {
        padding-right: 5.6rem;

        &.form-group__input-wrapper--custom {
          padding-right: 0;

          .form-group__warning-sign {
            right: auto;
          }
        }

        @include media-breakpoint-up(md) {
          padding-right: 0;

          .form-group__warning-sign {
            right: -4.8rem;
          }
        }
      }

      .help {
        right: 3rem;

        @include media-breakpoint-up(md) {
          right: -2.5rem;
        }
      }
    }

    &.form-group--has-error {

      .form-group__input-wrapper {
        padding-right: 5.6rem;

        &.form-group__input-wrapper--custom {
          padding-right: 0;

          .form-group__warning-sign {
            right: auto;
          }
        }

        @include media-breakpoint-up(md) {
          padding-right: 0;

          .form-group__warning-sign {
            right: -4.8rem;
          }
        }
      }

      .help {
        right: 3rem;

        @include media-breakpoint-up(md) {
          right: -2.5rem;
        }
      }
    }
  }

  &.form-group--mb-8 {
    margin-bottom: 0.8rem;
  }

  &.form-group--mb-4 {
    margin-bottom: 0.4rem;
  }

  &.form-group--custom-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    label {
      margin: 0;
    }

    .form-control {
      width: 4rem;
      margin: 0 0.8rem;
    }
  }
}

.form-group__input-wrapper {
  position: relative;

  &.form-group__input-wrapper--inline {
    display: flex;
    align-items: center;
  }

  &.form-group__input-wrapper--inline-block {
    display: inline-block;
  }

  &.form-group__input-wrapper--custom {
    display: flex;
    align-items: center;

    .form-control {
      width: 58.33%;
      max-width: 58.33%;
      flex-basis: 58.33%;

      @include media-breakpoint-up(sm) {
        width: 49.5%;
        max-width: 49.5%;
        flex-basis: 49.5%;
      }

      @include media-breakpoint-up(xl) {
        width: 9.6rem;
        max-width: 9.6rem;
        flex-basis: 9.6rem;
      }
    }

    @include media-breakpoint-up(xl) {
      display: inline-flex;
    }
  }

  &.form-group__input-wrapper--custom-2 {
    display: inline-flex;
    align-items: center;

    .form-group__after-text {
      flex-shrink: 0;
    }

    select {
      width: 11rem;

      @include media-breakpoint-up(sm) {
        width: 16.9rem;
      }

      @include media-breakpoint-up(md) {
        width: 14.2rem;
      }

      @include media-breakpoint-up(lg) {
        width: 10rem;
      }

      @include media-breakpoint-up(xl) {
        width: 13.8rem;
      }
    }
  }

  &.form-group__input-wrapper--custom-3 {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch {
      margin: 0 0 0.4rem 2rem;
    }
  }
}

.form-group__after-text {
  margin-left: 0.8rem;
  display: inline-block;

  &.form-group__after-text--small {
    font-size: 1.1rem;
    color: $blue-color-2;
  }
}

.form-group__custom-1 {
  display: inline-flex;
  align-items: center;
  position: relative;

  .form-control {
    width: 5.5rem;
    flex-basis: 5.5rem;
    min-width: 5.5rem;
  }

  .form-group__after-text {
    max-width: 7rem;
  }
}

/*--GEARBOX-RADIO--*/

.gearbox-radio {
  position: relative;
  text-align: center;
  height: 100%;
}

.gearbox-radio__input {
  position: absolute;
  top: 0.6rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;

  &:checked {

    &+.gearbox-radio__label {

      .gearbox-radio__name {
        font-weight: 900;
      }

      .gearbox-radio__info {
        font-weight: 900;
      }
    }
  }
}

.gearbox-radio__label {
  padding: 0.7rem;
  background-color: $blue-color-9;
  margin: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  border-radius: 0.7rem;
  cursor: pointer;

  &:hover {

    .gearbox-radio__name {
      font-weight: 900;
    }

    .gearbox-radio__info {
      font-weight: 900;
    }
  }
}

.gearbox-radio__name {
  display: block;
  margin-bottom: 0.6rem;
  min-height: 3rem;
}

.gearbox-radio__image {
  width: 6.5rem;

  &.gearbox-radio__image--large {
    transform: scale(1.7) translateY(-0.4rem);
  }
}

.gearbox-radio__info {
  display: block;
  font-size: 1.1rem;
  padding-top: 0.8rem;

  span {
    display: block;
  }
}

.gearbox-radio__light {
  color: $blue-color-2;
}

.gearbox-radio__btn {
  margin: 1.5rem 0 0.8rem 0;
}

/*--GEARBOX-RADIO-ADVANCED--*/

.gearbox-radio-advanced {
  position: relative;
  text-align: center;
  height: 100%;
}

.gearbox-radio-advanced__label {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  background-color: $blue-color-6;
  margin: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  -moz-user-select: none;
  border-radius: 0.7rem;
  cursor: pointer;

  &:hover {

    .gearbox-radio-advanced__name {
      font-weight: 900;
    }
  }
}

.gearbox-radio-advanced__input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gearbox-radio-advanced__input {

  &:checked {

    &+.gearbox-radio-advanced__name {
      font-weight: 900;
    }
  }
}

.gearbox-radio-advanced__name {
  display: block;
  margin-left: 0.5rem;
}

.gearbox-radio-advanced__info-wrapper {
  display: flex;
}

.gearbox-radio-advanced__image {
  width: 6rem;
  flex-shrink: 0;
  margin-left: -1.5rem;

  @include media-breakpoint-up(sm) {
    width: 7.5rem;
    margin-left: -1rem;
  }
}

.gearbox-radio-advanced__info {
  display: block;
  font-size: 1.1rem;
  text-align: left;
  margin: 1.6rem 0 0 0.3rem;

  span {
    display: block;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 2.4rem;
  }
}

.gearbox-radio-advanced__light {
  color: $blue-color-2;
}

/*--GEARBOX-RADIO-INLINE--*/

.gearbox-radio-inline {

}

.gearbox-radio-inline__wrapper {
  display: flex;
  padding-top: 1.4rem;
}

.gearbox-radio-inline__label {
  display: flex;
  margin-left: 0.7rem;
  margin-bottom: 0;
  flex-grow: 1;
  align-items: flex-start;
}

.gearbox-radio-inline__info {
  flex-grow: 1;
}

.gearbox-radio-inline__input {

  &:checked {

    &+.gearbox-radio-inline__label {
      font-weight: 900;
    }
  }
}

.gearbox-radio-inline__name {
  display: block;
  margin-bottom: 0.5rem;
}

.gearbox-radio-inline__light-text {
  display: block;
  font-size: 1.1rem;
  color: $blue-color-2;
  margin-bottom: 0.3rem;
}

.gearbox-radio-inline__image {
  width: 8.5rem;
  margin-top: -1.6rem;
}

/*--SWITCH--*/

.switch {
  user-select: none;
  -moz-user-select: none;

  &.switch--right {

    .switch__label {
      justify-content: flex-end;
    }
  }
}

.switch__input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked {

    &+.switch__label {

      .switch__button {

        &::after {
          transform: translateX(-1.8rem);
        }
      }
    }
  }
}

.switch__label {
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.switch__text {

}

.switch__button {
  position: relative;
  width: 4rem;
  height: 2.2rem;
  border: 0.2rem solid $blue-color-4;
  border-radius: 1.1rem;
  margin: 0 1.2rem;
  flex-shrink: 0;

  &::after {
    position: absolute;
    content: "";
    width: 1.4rem;
    height: 1.4rem;
    left: 2rem;
    top: 0.2rem;
    background-color: $blue-color-1;
    border-radius: 50%;
    transition: transform 300ms ease;
  }
}

.switch__toggle-block {
  display: none;
}