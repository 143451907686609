.toolbar {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: $blue-color-1;
  z-index: 999;
  padding: 1.5rem 0;
  text-align: center;
  transform: translateY(100%);
  transition: transform 300ms ease;

  &.toolbar--is-visible {
    transform: translateY(0);
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}