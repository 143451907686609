.accordion {
  padding: 0.2rem 0;
}

.accordion__card-header {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 0 1.5rem 0 0;
  color: $blue-color-1;
  cursor: pointer;
  position: relative;
  user-select: none;

  &::before {
    display: block;
    position: absolute;
    content: "";
    width: 1.8rem;
    height: 1.8rem;
    right: 0;
    top: 50%;
    margin-top: -0.9rem;
    background-image: url('../images/svg/arrow-down-blue.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
    transition: all 300ms ease;
    transform: rotateZ(-180deg);
  }

  &.collapsed {

    &::before {
      transform: rotateZ(0);
    }
  }
}

.accordion__card-body {
  padding-top: 1.5rem;
}

.accordion__card-header-light {
  color: $blue-color-2;
}