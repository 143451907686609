.block {
  margin-bottom: 2rem;

  &.block--mb-6 {
    margin-bottom: 0.6rem;
  }

  &.block--mb-10 {
    margin-bottom: 1rem;
  }

  &.block--mb-30 {
    margin-bottom: 3rem;
  }
}