.video-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(207,218,227,0.97);
  z-index: 9999;
  padding-top: 7rem;
  pointer-events: none;
  opacity: 0;
  transform: translateY(1rem);
  transition: all 500ms ease;

  &.video-modal--opened {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }
}

.video-modal__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    width: 4rem;
    height: 4rem;
    top: 3rem;
    right: 3rem;
  }
}

.video-modal__close-icon {
  position: absolute;
  left: 0.7rem;
  top: 0.7rem;
  width: 1.6rem;
  height: 1.6rem;
  fill: $blue-color-1;

  @include media-breakpoint-up(md) {
    left: 0.6rem;
    top: 0.6rem;
    width: 2.8rem;
    height: 2.8rem;
  }
}

.video-modal__wrapper {
  overflow: auto;
  height: 100%;
}

.video-modal__content {
  width: 100%;
  padding: 3rem 1.5rem;

  @include media-breakpoint-up(sm) {
    padding: 0;
    margin: auto;
  }

  @include media-breakpoint-up(md) {
    padding: 9rem 0 0 0;
    min-height: calc(100% - 9rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.video-modal__headline {
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 2rem 0 0 2rem;

  @include media-breakpoint-up(md) {
    font-size: 2.6rem;
    padding: 3rem 0 0 4.5rem;
  }
}

.video-modal__video {
  display: block;
  max-width: 90%;
  max-height: 75vh;
  width: 98%;
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    width: auto;
  }

  @include media-breakpoint-up(xl) {
    max-width: 95rem;
  }
}