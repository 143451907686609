.video-page {
  width: 100%;
  height: 100vh;
  background-color: $blue-color-12;
}

.video-page__headline {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  padding: 2rem 0 0 2rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up(md) {
    font-size: 2.6rem;
    padding: 3rem 0 0 4.5rem;
  }
}

.video-page__video {
  display: block;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 7rem);
  margin: 0 auto;

  @include media-breakpoint-up(md) {
    max-height: calc(100vh - 9rem);
  }
}