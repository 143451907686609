
/*--Roboto--*/

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-RegularItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Black.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Black.woff') format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BlackItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

/*--Ladislav--*/

@font-face {
  font-family: 'Ladislav';
  src: url('../fonts/Ladislav/LadislavSemiBold.woff2') format('woff2'),
  url('../fonts/Ladislav/LadislavSemiBold.woff') format('woff');
  font-weight: 600;
}