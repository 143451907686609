.image-modal__content {
  border: 0;
  border-radius: 0;
  padding: 2rem;
  display: block;
}

.image-modal__image {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  margin: auto;
}

.image-modal__close {
  position: absolute;
  width: 3rem;
  height: 3rem;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.image-modal__close-icon {
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  width: 1.4rem;
  height: 1.4rem;
  fill: $blue-color-1;
}