html {
  font-size: 62.5%;
}

body {
  font-size: 1.3rem;
  font-family: "Roboto", sans-serif;
  color: $blue-color-1;
  line-height: 1.2;
  font-weight: normal;

  &.overflow-hidden {
    overflow: hidden;
  }
}

.main {
  padding-top: 2.2rem;
  padding-bottom: 9.1rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 1.5rem;
  }
}

a {
  color: $blue-color-1;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: $blue-color-1;
  }
}