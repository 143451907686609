b, strong {
  font-weight: 900;
}

.headline-underline {
  position: relative;
  padding-bottom: 0.8rem;
  font-size: 1.5rem;
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: $blue-color-1;

  &::after {
    position: absolute;
    content: "";
    width: calc(100% + 1.2rem);
    height: 0.1rem;
    left: -0.6rem;
    bottom: 0;
    background-color: $blue-color-1;
  }
}