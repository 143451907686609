.header {
  padding: 2rem 0;
  background-color: $blue-color-1;
}

.header__container {

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header__logo {
  position: relative;
  display: block;
  padding: 0.5rem 0 0.5rem 10rem;
  color: #fff;
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.7rem;
  text-decoration: none;

  sup {
    top: 0;
    font-size: 110%;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    padding: 1rem 0 1rem 14.5rem;
    font-size: 2rem;
    flex-shrink: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 16.5rem;
  }
}

.header__logo-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 8rem;
  height: 8rem;
  fill: $blue-color-3;

  @include media-breakpoint-up(md) {
    width: 11.5rem;
    height: 11.5rem;
  }
}

.header__right {

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
  }
}

.header__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }

  .btn {
    margin: 0.8rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.header__select {
  text-align: center;
  margin-top: 1.5rem;

  select {
    padding-right: 4.2rem;
  }

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    margin-left: 2rem;
  }

  @include media-breakpoint-up(md) {
    flex-shrink: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 4.2rem;
  }
}