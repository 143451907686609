.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $blue-color-4;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms ease;

  &::before {
    position: absolute;
    content: "";
    width: 15rem;
    height: 15rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    background: url('../images/gears-loader.gif') center/100% no-repeat;
    transition: transform 300ms ease;
  }

  &.loading--shown {
    opacity: 0.7;
    pointer-events: auto;

    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}