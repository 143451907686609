$blue-color-1: #003057;
$blue-color-2: #7f97ab;
$blue-color-3: #e7ecf1;
$blue-color-4: #baddea;
$blue-color-5: #7cb5ca;
$blue-color-6: #ecf0f4;
$blue-color-7: #012644;
$blue-color-8: #b9c7d2;
$blue-color-9: #e6f1f5;
$blue-color-10: #277997;
$blue-color-11: #216481;
$blue-color-12: #cfdae3;
$orange-color-1: #f37443;
$orange-color-2: #c8613c;
$yellow-color-1: #ffba00;
$yellow-color-2: #fffaee;
$yellow-color-3: #b98700;
$yellow-color-4: #f1ac00;
$red-color-1: #a94442;
$red-color-2: #ffeaea;
$red-color-3: #783533;
$red-color-4: #e51111;
$red-color-5: #c00e0e;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1140px
)