.gearbox-forces {
  position: relative;
  height: 20rem;
  margin-bottom: 1rem;

  .form-group, [class^="col"] {
    position: static;
  }
}

.gearbox-forces__image {
  position: absolute;
  width: 16.6rem;
  height: 8.8rem;
  left: 50%;
  top: 9rem;
  transform: translateX(-50%);
}

.gearbox-forces__arrow-reverse {
  display: flex;

  &.gearbox-forces__arrow-reverse--1 {

    .gearbox-forces__arrow {
      top: 13.7rem;
      margin-left: -13.6rem;
    }

    .gearbox-forces__btn {

    }

    .gearbox-forces__arrow-reverse-input {

      &:checked {

        &+.gearbox-forces__arrow-reverse-label {

          .gearbox-forces__arrow {
            background-image: url('../images/svg/arrow-left-green.svg');
          }
        }
      }
    }
  }

  &.gearbox-forces__arrow-reverse--2 {

    .gearbox-forces__arrow {
      top: 5.5rem;
      margin-left: 0.9rem;
      transform: rotateZ(90deg);
    }

    .gearbox-forces__btn {

      .gearbox-forces__btn-icon {
        transform: rotateZ(90deg);
      }
    }

    .gearbox-forces__arrow-reverse-input {

      &:checked {

        &+.gearbox-forces__arrow-reverse-label {

          .gearbox-forces__arrow {
            background-image: url('../images/svg/arrow-left-green.svg');
          }
        }
      }
    }
  }
}

.gearbox-forces__arrow-reverse-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.gearbox-forces__arrow-reverse-label {
  margin: 0;
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;

  &:active {

    .gearbox-forces__btn {
      transform: scale(0.9);
    }
  }
}

.gearbox-forces__arrow {
  position: absolute;
  width: 5rem;
  height: 1.5rem;
  left: 50%;
  background-image: url('../images/svg/arrow-right-green.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
}

.gearbox-forces__btn {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.4rem;
  border: 0;
  transition: transform 150ms ease;
  pointer-events: none;
  background-color: $blue-color-1;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $blue-color-7;
  }
}

.gearbox-forces__btn-icon {
  position: absolute;
  width: 1.1rem;
  height: 1.3rem;
  fill: #fff;
  left: 0.7rem;
  top: 0.6rem;
}

.gearbox-forces__help {
  position: relative;
  top: auto;
  transform: none;
  margin: 0.4rem;
}