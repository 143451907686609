.alert {
  position: absolute;
  width: calc(100% - 3rem);
  left: 1.5rem;
  bottom: 100%;
  border: 0;
  border-radius: 0.4rem;
  padding: 1.4rem 2rem;
  margin: 0 0 0.8rem 0;
  opacity: 1;
  transition: opacity 300ms ease;
  box-shadow: 0 0 1.5rem rgba(0,0,0,0.1);

  &::after {
    position: absolute;
    content: "";
    bottom: -0.8rem;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.8rem 0.5rem 0 0.5rem;
    transform: translateX(-50%);
  }

  &.alert--hiding {
    opacity: 0;
  }

  &.alert--hidden {
    opacity: 0;
    display: none;
  }
}

.alert-warning {
  background-color: $yellow-color-2;
  color: $yellow-color-3;

  &::after {
    border-color: $yellow-color-2 transparent transparent transparent;
  }

  .alert__close-icon {
    fill: $yellow-color-3;
  }
}

.alert-error {
  background-color: $red-color-2;
  color: $red-color-3;

  &::after {
    border-color: $red-color-2 transparent transparent transparent;
  }

  .alert__close-icon {
    fill: $red-color-3;
  }
}

.alert__close {
  position: absolute;
  width: 1.4rem;
  height: 1.4rem;
  top: 0.7rem;
  right: 0.7rem;
  border: 0;
  background: none;
}

.alert__close-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}