.summary {

  @include media-breakpoint-up(md) {

    &.summary--has-overlay {
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(231,236,241,0.8);
        z-index: 1;
      }
    }
  }
}

.summary__overlay-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  &:active {
    transform: translate(-50%, -50%);
  }
}

.summary__section {
  background-color: $blue-color-3;
  padding: 1.5rem 2rem;
  margin-bottom: 0.1rem;

  &.summary__section--1 {
    min-height: 21rem;
  }

  &.summary__section--2 {
    padding-top: 2.2rem;
    padding-bottom: 1.8rem;
    min-height: 12rem;
  }
}

.summary__headline {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 1rem;

  &.summary__headline--has-help {
    display: flex;

    .help {
      position: relative;
      top: auto;
      right: auto;
      transform: none;
      margin: 0.1rem 0 0 0.8rem;
    }
  }
}

.summary__check {
  margin-bottom: 1rem;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
}

.summary__check-label {
  flex-grow: 1;
}

.summary__check-label-info {
  display: flex;
  font-weight: normal;

  &.summary__check-label-info--multiline {
    flex-wrap: wrap;

    .summary__check-label-value {
      margin-right: 1.4rem;

      &+.summary__check-label-value {
        margin-left: 0;
      }
    }
  }
}

.summary__check-label-value {
  font-size: 1.1rem;

  &+.summary__check-label-value {
    margin-left: 1.4rem;
  }
}

.summary__check-label-value-name {
  color: $blue-color-2;
}

.summary__table {
  width: 100%;
  margin-bottom: 1.5rem;

  td {
    padding: 0 0.5rem;

    &:nth-of-type(1) {
      padding-left: 0;
    }

    &:nth-of-type(2) {
      width: 25%;
    }

    &:nth-of-type(3) {
      width: 15%;
      padding-right: 0;
    }
  }
}

.summary__blueprint {
  position: relative;
  height: 9.5rem;
  background-color: #fff;
  margin-bottom: 1rem;

  &.summary__blueprint--has-image {
    cursor: pointer;

    .summary__blueprint-icon {
      opacity: 1;
    }
  }
}

.summary__blueprint__image {
  position: absolute;
  width: calc(100% - 1.6rem);
  height: calc(100% - 1.6rem);
  left: 0.8rem;
  top: 0.8rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.summary__blueprint-icon {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  top: 0.8rem;
  right: 0.8rem;
  fill: $blue-color-1;
  opacity: 0;
}

.summary__notes {
  display: none;

  .summary__notes__textarea {
    width: 100%;
    height: 6rem;
    border: 0;
    padding: 1rem;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  }
}

/*--IE--*/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .summary__check-label-info {

    &.summary__check-label-info--multiline {
      display: block !important;
    }
  }

  .summary__check-label-value {
    display: inline-block !important;
  }
}