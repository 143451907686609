.tiles {

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}

.tiles__tile {
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    flex-basis: 50%;
    width: 50%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(md) {
    flex-basis: 33.33%;
    width: 33.33%;
  }
}

.tiles__tile-content {
  position: relative;
  display: block;
  background-color: $blue-color-1;
  padding: 3rem;
  border-radius: 1.5rem;
  background-image: url('../images/radial-gradient.png');
  background-size: 180%;
  background-position: center 80%;
  background-repeat: no-repeat;
  text-decoration: none;

  &:hover {
    text-decoration: none;

    .tiles__tile-image-wrapper {

      @include media-breakpoint-up(xl) {
        transform: scale(1.06);
      }
    }
  }

  @include media-breakpoint-up(sm) {
    height: 100%;
  }

  @include media-breakpoint-up(md) {
    background-position: center 100%;
  }

  @include media-breakpoint-up(lg) {
    padding: 3.5rem 3rem 5rem 3rem;
  }
}

.tiles__tile-image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin-bottom: 3.5rem;

  @include media-breakpoint-up(lg) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-up(xl) {
    transition: transform 300ms ease;
    transform: scale(1.001);
  }
}

.tiles__tile-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tiles__tile-headline {
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  text-align: center;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 2.5rem;
}

.tiles__tile-btn-container {
  display: flex;
  justify-content: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .tiles__tile-image {
    height: auto;
  }
}