
.form-modal {


  textarea {

    &.form-control {
      min-height: 12.5rem;
    }
  }
}

.form-modal__dialog {

  @include media-breakpoint-up(md) {
    max-width: 64rem;
  }
}

.form-modal__content {
  border: 0;
  border-radius: 0;
  background: rgba(255,255,255,0.9);
}

.form-modal__header {
  position: relative;
  padding: 2rem 0 2rem 2rem;
  background-color: $blue-color-1;

  @include media-breakpoint-up(md) {
    padding-left: 4rem;
  }
}

.form-modal__logo {
  position: relative;
  display: block;
  padding: 0.5rem 0 0.5rem 10rem;
  color: #fff;
  font-family: "Ladislav", sans-serif;
  font-weight: 600;
  font-size: 1.7rem;

  @include media-breakpoint-up(md) {
    padding: 1rem 0 1rem 14rem;
    font-size: 2rem;
  }
}

.form-modal__logo-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 8rem;
  height: 8rem;
  fill: $blue-color-3;

  @include media-breakpoint-up(md) {
    width: 11.5rem;
    height: 11.5rem;
  }
}

.form-modal__close {
  position: absolute;
  width: 1.8rem;
  height: 1.8rem;
  top: 50%;
  right: 2rem;
  cursor: pointer;
  transform: translateY(-50%);

  @include media-breakpoint-up(md) {
    width: 2.8rem;
    height: 2.8rem;
    right: 4rem;
  }
}

.form-modal__close-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  fill: #fff;
}

.form-modal__section {
  padding-left: 3rem;
  padding-right: 3rem;

  &.form-modal__section--1 {
    padding-top: 4rem;
    padding-bottom: 3rem;
    border-bottom: 0.1rem solid rgba(185,199,210,0.2);

    @include media-breakpoint-up(md) {
      padding-top: 6rem;
      padding-bottom: 3.5rem;
    }
  }

  &.form-modal__section--2 {
    padding-top: 3rem;
    padding-bottom: 4rem;

    @include media-breakpoint-up(md) {
      padding-top: 4rem;
      padding-bottom: 6rem;
    }
  }

  @include media-breakpoint-up(md) {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.modal-backdrop {
  background-color: $blue-color-3;

  &.show {
    opacity: 0.75;
  }
}